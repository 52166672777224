<template>
	<div class="video-view-wrapper" :key="key">
		<video
			v-if="videoSrc !== ''"
			class="video-js vjs-default-skin"
			id="myPlayer"
			crossOrigin="anonymous"
			autoplay controls='controls'
			:src="videoSrc">
			<source v-if="videoSrc !== ''" :src="videoSrc" />
		</video>
		<div v-else class="failure">视频加载失败，请检查设备后重试</div>
	</div>
</template>

<script>
export default {
	name: 'executiveVideo',
	props: ['videoSrc', 'keyStatus'],
	data () {
		return {
			key: 0
		}
	},
	watch: {
		keyStatus () {
			this.key = Math.random()
			this.$nextTick(() => {
				this.$emit('videoReady')
			})
		}
	},
	mounted () {},
	methods: {}
}
</script>

<style lang="scss" scoped>
.video-view-wrapper {
	height: 628px;
	padding: 0 40px;
	line-height: 628px;
	.vjs-default-skin {
		width: 80%;
		height: 100%;
		margin: 0 auto;
	}
	.failure {
		font-size: 20px;
		text-align: center;
	}
}
</style>
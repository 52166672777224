var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "patrolUpdate-container" },
    [
      _c(
        "form-panel",
        {
          scopedSlots: _vm._u([
            {
              key: "headerSlot",
              fn: function () {
                return [
                  _c("v-button", {
                    attrs: { text: "返回" },
                    on: { click: _vm.previous },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.isVideo
            ? _c("executiveVideo", {
                attrs: { videoSrc: _vm.vadioSrc, keyStatus: _vm.keyStatus },
                on: { videoReady: _vm.handleVideoReady },
              })
            : _vm._e(),
          _vm.processList.length !== 0
            ? _c(
                "div",
                { staticClass: "steps-view-wrapper" },
                [
                  _c(
                    "el-steps",
                    {
                      attrs: {
                        active: _vm.progressActive,
                        space: 200,
                        "align-center": "",
                      },
                    },
                    _vm._l(_vm.processList, function (item, index) {
                      return _c("el-step", { key: index }, [
                        _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                          _vm._v(_vm._s(item.pointName)),
                        ]),
                      ])
                    }),
                    1
                  ),
                ],
                1
              )
            : _c("div", { staticClass: "steps-view-wrapper" }, [
                _vm._v("\n      暂无巡更路线，请联系管理员\n    "),
              ]),
          _vm.processList.length !== 0
            ? _c(
                "div",
                { staticClass: "footerBtn" },
                [
                  _vm.progressActive >= 1
                    ? _c(
                        "v-button",
                        {
                          staticStyle: { "margin-left": "12px" },
                          attrs: { disabled: _vm.isNextDisabled },
                          on: { click: _vm.onBefor },
                        },
                        [_vm._v("上一步")]
                      )
                    : _vm._e(),
                  _c(
                    "v-button",
                    {
                      staticStyle: { "margin-left": "12px" },
                      attrs: { disabled: _vm.isNextDisabled },
                      on: { click: _vm.next },
                    },
                    [
                      _vm._v(_vm._s(_vm.btnText)),
                      _vm.count
                        ? _c("span", [_vm._v("(" + _vm._s(_vm.count) + "s)")])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "v-button",
                    {
                      staticStyle: { "margin-left": "12px" },
                      on: { click: _vm.snapshot },
                    },
                    [_vm._v("异常提报")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            title: "工单",
            width: "600px",
            visible: _vm.isShow,
            "modal-append-to-body": false,
            "append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
            closed: _vm.closeQrcodeDialog,
          },
        },
        [
          _c("div", { staticClass: "dialog-box" }, [
            _c(
              "div",
              { staticClass: "dialog-centent" },
              [
                _c(
                  "form-panel",
                  {
                    ref: "form",
                    attrs: {
                      form: _vm.form,
                      hasHeader: false,
                      labelWidth: "130px",
                    },
                  },
                  [
                    _c(
                      "col2-detail",
                      [
                        _c(
                          "col2-block",
                          [
                            _vm.isNewOrder
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "工单类型",
                                        prop: "categoryName",
                                        rules: [
                                          {
                                            required: true,
                                            message: "选择分类",
                                            trigger: "blur",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("el-cascader", {
                                        attrs: {
                                          props: { expandTrigger: "hover" },
                                          options: _vm.orderTypeList,
                                        },
                                        on: { change: _vm.changeCascader },
                                        model: {
                                          value: _vm.orderTypeValue,
                                          callback: function ($$v) {
                                            _vm.orderTypeValue = $$v
                                          },
                                          expression: "orderTypeValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "巡更要点",
                                        prop: "childId",
                                        rules: [
                                          {
                                            required: true,
                                            message: "选择巡更要点",
                                            trigger: "blur",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择巡更要点",
                                          },
                                          model: {
                                            value: _vm.form.childId,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "childId", $$v)
                                            },
                                            expression: "form.childId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.patrolContentsOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.contentId,
                                              attrs: {
                                                label: item.content,
                                                value: item.contentId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "工单类型",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请选择工单类型",
                                            trigger: "blur",
                                          },
                                        ],
                                        prop: "code",
                                      },
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "oneSelect",
                                        attrs: { options: _vm.orderTypeOps },
                                        on: { change: _vm.onSelectOne },
                                        model: {
                                          value: _vm.form.subjectId,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "subjectId", $$v)
                                          },
                                          expression: "form.subjectId",
                                        },
                                      }),
                                      _c("v-select", {
                                        attrs: { options: _vm.orderType2Ops },
                                        model: {
                                          value: _vm.form.code,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "code", $$v)
                                          },
                                          expression: "form.code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "工单内容",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请填写工单内容",
                                      trigger: "blur",
                                    },
                                  ],
                                  prop: "content",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                视频位置：" +
                                    _vm._s(_vm.location) +
                                    "\n                "
                                ),
                                _c("v-input", {
                                  attrs: { type: "textarea", width: _vm.width },
                                  model: {
                                    value: _vm.form.content,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "content", $$v)
                                    },
                                    expression: "form.content",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "工单图片" } },
                              [
                                _c("div", { staticClass: "order-img" }, [
                                  _c("img", {
                                    attrs: { src: _vm.imgUrl, alt: "" },
                                  }),
                                ]),
                              ]
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "dialog-btn" },
              [
                _c(
                  "v-button",
                  {
                    attrs: { disabled: _vm.btnStatus },
                    on: { click: _vm.onConfirm },
                  },
                  [_vm._v(_vm._s(!_vm.btnStatus ? "确定" : "上传图片中..."))]
                ),
                _c(
                  "v-button",
                  { attrs: { type: "" }, on: { click: _vm.closeQrcodeDialog } },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("canvas", {
        staticStyle: { display: "none", width: "600px", height: "400px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: _vm.key, staticClass: "video-view-wrapper" }, [
    _vm.videoSrc !== ""
      ? _c(
          "video",
          {
            staticClass: "video-js vjs-default-skin",
            attrs: {
              id: "myPlayer",
              crossOrigin: "anonymous",
              autoplay: "",
              controls: "controls",
              src: _vm.videoSrc,
            },
          },
          [
            _vm.videoSrc !== ""
              ? _c("source", { attrs: { src: _vm.videoSrc } })
              : _vm._e(),
          ]
        )
      : _c("div", { staticClass: "failure" }, [
          _vm._v("视频加载失败，请检查设备后重试"),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="patrolUpdate-container">
    <form-panel>
      <template #headerSlot>
        <v-button text="返回" @click="previous"></v-button>
      </template>
      <!-- <div class="video-view-wrapper"> -->
        <executiveVideo v-if="isVideo" :videoSrc="vadioSrc" @videoReady="handleVideoReady" :keyStatus="keyStatus"/>
        <!-- <video
          v-if="isVideo && vadioSrc !== ''"
          class="video-js vjs-default-skin"
          id="myPlayer"
          crossOrigin="anonymous"
          autoplay controls='controls'
          :src="vadioSrc">
          <source v-if="vadioSrc !== ''" :src="vadioSrc" />
        </video>
        <div v-else class="failure">视频加载失败，请检查设备后重试</div> -->
      <!-- </div> -->
      <div class="steps-view-wrapper" v-if="processList.length !== 0">
        <el-steps :active="progressActive" :space="200" align-center>
          <el-step v-for="(item, index) in processList" :key="index">
            <div slot="title">{{item.pointName}}</div>
          </el-step>
        </el-steps>
      </div>
      <div class="steps-view-wrapper" v-else>
        暂无巡更路线，请联系管理员
      </div>
      <div class="footerBtn" v-if="processList.length !== 0">
        <v-button style="margin-left: 12px;" v-if="progressActive >= 1" :disabled="isNextDisabled" @click="onBefor">上一步</v-button>
        <v-button style="margin-left: 12px;" :disabled="isNextDisabled" @click="next">{{btnText}}<span v-if="count">({{count}}s)</span></v-button>
        <v-button style="margin-left: 12px;" @click="snapshot">异常提报</v-button>
      </div>
     </form-panel>
    <el-dialog
      title="工单"
      width="600px"
      v-loading="loading"
      :visible.sync="isShow"
      :modal-append-to-body="false"
      :append-to-body="false"
      @closed="closeQrcodeDialog"
    >
      <div class="dialog-box">
        <div class="dialog-centent">
          <form-panel ref="form" :form="form" :hasHeader="false" labelWidth="130px">
            <col2-detail>
              <col2-block>
                <template v-if="isNewOrder">
                  <el-form-item label="工单类型" prop="categoryName" :rules="[{ required: true, message: '选择分类', trigger: 'blur' }]">
                    <el-cascader
                      v-model="orderTypeValue"
                      :props="{ expandTrigger: 'hover' }"
                      :options="orderTypeList"
                      @change="changeCascader"
                    >
                    </el-cascader>
                  </el-form-item>
                  <el-form-item label="巡更要点" prop="childId" :rules="[{ required: true, message: '选择巡更要点', trigger: 'blur' }]">
                    <el-select v-model="form.childId" placeholder="请选择巡更要点">
                      <el-option
                        v-for="item in patrolContentsOptions"
                        :key="item.contentId"
                        :label="item.content"
                        :value="item.contentId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
                <template v-else>
                  <el-form-item label="工单类型" :rules="[{ required:true, message: '请选择工单类型', trigger: 'blur' }]" prop="code">
                    <v-select class="oneSelect" v-model="form.subjectId" :options="orderTypeOps" @change="onSelectOne" ></v-select>
                    <v-select v-model="form.code" :options="orderType2Ops"></v-select>
                  </el-form-item>
                </template>

                <el-form-item label="工单内容" :rules="[{ required:true, message: '请填写工单内容', trigger: 'blur' }]" prop="content">
                  视频位置：{{location}}
                  <v-input type="textarea" v-model="form.content" :width="width"></v-input>
                </el-form-item>
                <el-form-item label="工单图片">
                  <!-- <v-uploader
                    action=''
                    :imgUrls.sync="form.imgUrl"
                    :width="width"
                  /> -->
                  <div class="order-img">
                    <img :src="imgUrl" alt="">
                  </div>
                </el-form-item>
              </col2-block>
            </col2-detail>
          </form-panel>
        </div>
        <div class="dialog-btn">
          <v-button @click="onConfirm" :disabled="btnStatus">{{ !btnStatus ? '确定' : '上传图片中...'}}</v-button>
          <v-button type="" @click="closeQrcodeDialog">取消</v-button>
        </div>
      </div>
    </el-dialog>
    <canvas style="display:none;width:600px;height:400px;"></canvas>
  </div>
</template>

<script>
import Compressor from 'compressorjs'
import { Steps, Step, Popover } from 'element-ui'
import videojs from 'video.js'
import 'videojs-flash'
import { saveRouteUrl, getStartVideoPatrolURL, dotURL, getOneLevelURL, getTwoLevelURL, workOrderURL, executeURL, accessDeviceLiveAddressURL,
  checkNewWorkOrder, getTreeByRegionIdURL, taskAdd
} from './api'
import { Col2Block, Col2Detail } from 'components/bussiness'
import Vue from 'vue'
import { TimelineItem } from 'element-ui'
import moment from 'moment'
import { vUploader } from 'components/control'
import { uploadURL } from 'common/api'
import executiveVideo from './video'

Vue.use(TimelineItem)
moment.locale('zh-cn')

export default {
  name: 'routeUpdateForm',
  components: {
    Steps,
    Step,
    Col2Block,
    Col2Detail,
    vUploader,
    executiveVideo
  },
  data () {
    return {
      keyStatus: true,
      submitUrl: saveRouteUrl,
      submitConfig: {
        // submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        submitMethod: this.$route.query.id ? 'put' : 'post'
      },
      orderTypeOps: [{
        text: '请选择',
        value: undefined
      }],
      orderType2Ops: [{
        text: '请选择',
        value: undefined
      }],
      form: {
        communityId: this.$route.query.communityId,
        subjectId: undefined,
        code: undefined,
        content: '',
        imagUrl: '',
        subCode: '',
        categoryId: null, // 新工单id
        categoryName: '', // 新工单name
        taskInfoAddress: '',// 报事人报事地址
        childId:'',
      },
      player: null,
      processList: [],
      progressActive: 0,
      isNextDisabled: true,
      count: 0,
      minWatchTime: 0,
      isShow: false,
      isVideo: true,
      imgUrl: '',
      width: 300,
      compressCount: 1,
      location: '',
      parentId: '',
      childId: '',
      loading: true,
      vadioSrc: '',
      btnStatus: false,
      isNewOrder: false,
      orderTypeValue:[],
      orderTypeList: [],// 工单分类列表
      patrolContentsOptions: []
    }
  },
  computed: {
    btnText () {
      let length = this.processList.length - 1
      return this.progressActive >= this.processList[length].index ? '完成' : '下一步'
    }
  },
  created () {
    this.getStartVideoPatrol()
    this.getOrderTypeOps()
  },
  watch: {
    progressActive (newValue) {
      // console.log('1234', newValue, this.processList)
      this.getSrc()
    },
    vadioSrc (newValue) {
      if (newValue === '') {
        this.isVideo = false
      } else {
        this.isVideo = true
      }
      if(newValue !== '' && !this.player) {
        this.$nextTick(() => {
          this.initPlayer()
        })
      }
    }
  },
  mounted () {
  },
  beforeDestroy () {
    this.player && this.player.dispose()
  },
  methods: {
    // 获取视频地址
    async getSrc () {
      this.vadioSrc = '';
      if (this.progressActive > this.processList.length - 1) {
        // this.vadioSrc = this.processList[this.processList.length - 1].rtmp
        let accessDeviceParams = {
          cameraId: this.processList[this.processList.length - 1].cameraId,
          manufacturer: this.processList[this.processList.length - 1].manufacturer,
          communityId: this.processList[this.processList.length - 1].communityId,
        }
        await this.getAccessDeviceLiveAddress(accessDeviceParams)
      } else {
        let accessDeviceParams = {
          cameraId: this.processList[this.progressActive].cameraId,
          manufacturer: this.processList[this.progressActive].manufacturer,
          communityId: this.processList[this.progressActive].communityId,
        }
        // this.vadioSrc = this.processList[this.progressActive].rtmp
        await this.getAccessDeviceLiveAddress(accessDeviceParams)
      }
      // console.log(this.vadioSrc, 'this.vadioSrc')
      // console.log(this.vadioSrc, this.key);
      // this.$nextTick(() => {
      //   this.player && this.player.load()
      // })
    },
    // 返回
    goback () {
      this.$router.go(-1)
    },
    // 获取视频地址
    async getAccessDeviceLiveAddress (accessDeviceParams) {
      let { cameraId, manufacturer, communityId } = accessDeviceParams;
      let res = await this.$axios.post(accessDeviceLiveAddressURL + `?id=${cameraId}&manufacturer=${manufacturer}&communityId=${communityId}`)
      if (res.status === 100) {
        let data = res.data;
        this.player = null;
        this.vadioSrc =  data[0].hls
        console.log(this.player, 'this.player')
      }
    },
    // 获取一级分类
    getOrderTypeOps () {
      this.$axios.get(getOneLevelURL)
        .then((res) => {
          if (res.status === 100) {
            this.orderTypeOps = [{
              text: '请选择',
              value: undefined
            }]
            res.data.forEach((order) => {
              this.orderTypeOps.push({
                text: order.subName,
                value: order.id,
                subCode: order.subCode,
              },)
            })
          }
        })
    },
    // 获取二级分类
    getOrderType2Ops (code) {
      this.$axios.get(`${getTwoLevelURL}?code=${code}`)
        .then((res) => {
          if (res.status === 100) {
            this.orderType2Ops = [{
              text: '请选择',
              value: undefined
            }]
            res.data.forEach((order) => {
              this.orderType2Ops.push({
                text: order.value,
                value: order.code
              },)
            })
          }
        })
    },
    // 获取详情
    async getStartVideoPatrol () {
      this.handleRefreshVideo()
      await this.$axios.get(`${getStartVideoPatrolURL}${this.$route.query.routeLogId}`)
        .then((res) => {
          if (res.status === 100) {
            this.minWatchTime = res.data.minWatchTime
            this.processList = []
            let currentIndex = 0
            res.data && res.data.points.forEach((item, index) => {
              item.index = index
              if (item.appStatus === 1) {
                // 状态为1的视频表示已巡更完成
                // this.progressActive = index + 1
                currentIndex = index + 1
              }
              // 所有视频皆可回看
              this.processList.push(item)
            })
            if (currentIndex) {
              this.progressActive = currentIndex
            } else {
              this.getSrc()
            }
            if (res.data.points.length !== 0) {
              // this.getSrc()
              this.getTime()
            }
          }
        })
    },
    // 按钮倒计时
    getTime () {
      this.count = this.minWatchTime;
      this.isNextDisabled = true
      let time = setInterval(() => {
        if (this.count === 0) {
          this.isNextDisabled = false
          clearInterval(time)
          return
        }
        this.count--
      }, 1000)
    },
    // 播放视频
    initPlayer () {
      let self = this
      console.log(this.vadioSrc, 'this.vadioSrc3')
      this.player = videojs('myPlayer', {
        muted:true,
        controls: true,
        autoplay: "muted",
        loop:true, //是否循环播放
        hls:{
          withCredentials:true
        }
      }, function () {
        console.log('videojs播放器初始化成功', self.vadioSrc)
        let player = this
        setTimeout(() => {
          player.play()
        }, 1000)
        this.on('error', function (e) {
          self.isVideo = false
          console.log(e)
        })
      })
    },
    // 点击一级分类
    onSelectOne (value, option) {
      if (value !== undefined) {
        this.form.subCode = option.subCode;
        this.getOrderType2Ops(option.subCode)
      }
    },
    // 提交巡更人
    async execute () {
      let routeLogId = ''
      this.processList.forEach((process) => {
        if (process.index === this.progressActive) {
          routeLogId = process.routeLogId
        }
      })
      await this.$axios.post(`${executeURL}?routeLogId=${routeLogId}`)
        .then((res) => {
          if (res.status === 100) {
            // this.$message.success('当前巡更点，已巡更！')
          }
        })
    },
    // 提交此次巡更
    async dot () {
      let pointLogId = ''
      this.processList.forEach((process) => {
        if (process.index === this.progressActive) {
          pointLogId = process.id
        }
      })

      let param = {
        completeTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        pointLogId : pointLogId,
        type : this.$route.query.patrolMethod
      }
      await this.$axios.post(`${dotURL}?${this.$qs.stringify(param)}`)
        .then((res) => {
          if (res.status === 100) {
            let length = this.processList.length
            // 刷新步骤条
            if (this.progressActive < this.processList[length-1].index) {
              this.progressActive++
              this.getStartVideoPatrol()
            } else {
              this.$router.push({
                name: 'electronic3'
              })
            }
            this.$message.success('当前巡更点，已巡更！')
          }
        })
    },
    // 下一步
    next() {
        this.handleRefreshVideo()
        // console.log(this.progressActive, 'next');
        if (this.processList[this.progressActive].appStatus === 1) {
          // console.log('666');
          this.progressActive++
          // this.handleRefreshVideo()
        } else {
          // 如果是第一次巡更，需要判定巡更人员
          if (this.progressActive === 0 && this.processList[0].appStatus === 0) {
            this.execute()
          }
          this.dot()
        }
    },
    // 上一步
    onBefor () {
      // console.log(this.progressActive, 'prev');
      this.progressActive--
      this.handleRefreshVideo()
    },
    handleRefreshVideo () {
      this.player && this.player.dispose()
      this.keyStatus = !this.keyStatus
      // this.$nextTick(() => {
      //   this.initPlayer()
      // })
    },
    handleVideoReady () {
      this.$nextTick(() => {
        this.initPlayer()
      })
    },
    // 异常确认提交
    onConfirm () {
      let result = this.$refs.form.validate()
      if (!result) {
        return
      }
      if(this.isNewOrder){
        const params = {
          communityId: this.form.communityId,// 社区ID
          categoryId: this.form.categoryId, // 工单类型ID
          categoryName: this.form.categoryName,// 工单类别名称
          description: '视频位置：' + this.location  + ' ' + this.form.content,// 工单描述
          taskInfoAddress: this.location,//报事人报事地址
          images: this.form.imagUrl,
          workOrderExtend: {
            childId: this.form.childId, //关联子id
            parentId: this.parentId,// 关联父id
            type: 1, // 1:'电子巡更—巡更内容报事',2:'电子巡更-损坏蓝牙巡更点报事',3:'巡查管理-巡查内容报事',4:'仪表抄表',5:'设备巡检-对巡检内容进行报事',6:'设施设备',7:'设备维保'
          }
        }
        this.$axios.post(`${taskAdd}`, params)
          .then((res) => {
            if (res.status === 100) {
              this.isShow = false
              this.$message.success("异常提交成功！")
            }
          })
      }else {
        let params = {
          communityId: this.form.communityId,
          subjectId: this.form.subjectId,
          code: this.form.code,
          content: this.location +  this.form.content,
          imagUrl: this.form.imagUrl,
          subCode: this.form.subCode
        }
        this.$axios.post(workOrderURL, params)
          .then((res) => {
            if (res.status === 100) {
              this.isShow = false
              this.$message.success("异常提交成功！")
            }
          })

      }
    },
    // 返回
    async previous () {
      const isOK = await this.$confirm('您还未完成所有巡更任务点，是否确认返回？', {
        title: '提示'
      })
      isOK && this.$router.go(-1)
    },
    // 异常截图与弹出窗
    async snapshot () {
      // 判断新老工单
      await this.checkNewWorkOrder()

      // 每次打开弹窗初始化表单
      this.form.content = ''
      this.form.subjectId = undefined
      this.form.code = undefined
      this.location = this.processList[this.progressActive].pointName
      this.parentId = this.processList[this.progressActive].pointId
      this.patrolContentsOptions = this.processList[this.progressActive].patrolContents 
      if (this.vadioSrc !== '') {
        // 截图
        var video = document.querySelectorAll("video")[0]; //获取前台要截图的video对象，
        var canvas = document.querySelectorAll('canvas')[0]; //获取前台的canvas对象，用于作图
        var w = parseInt(window.getComputedStyle(video).width);
        var h = parseInt(window.getComputedStyle(video).height);
        canvas.width = w * 4;
        canvas.height = h * 4;
        canvas.style.width = w + "px";
        canvas.style.height = h + "px";
        var ctx = canvas.getContext('2d'); //设置canvas绘制2d图，
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height); //将video视频绘制到canvas中
        var images = canvas.toDataURL('image/png'); //canvas的api中的toDataURL()保存图像
        this.imgUrl = images
        if (!images) {
          this.btnStatus = false
        }
        // 压缩上传图片
        let blob = this.dataURLtoFile(images)
        this.compressSnapshot(blob)
        // 暂停视频
        this.player && this.player.pause()
      }
      // 打开弹窗
      this.isShow = true
    },
    // 上传图片
    async uploadImg (formData) {
      if (formData) {
        this.btnStatus = true
      }
      await this.$axios.post(`${uploadURL}?type=order`, formData).then((res) => {
          if (res.status === 100) {
            this.form.imagUrl = res.data[0]
            this.btnStatus = false
          } else {
              this.$message("上传图片失败，请重新上传。")
          }
      })
    },
    // 压缩图片
    compressSnapshot (blob) {
      // 压缩要上传的快照图片
      let self = this
      new Compressor(blob, { // eslint-disable-line
          quality: 0.6,
          success (result) {
            let file = new window.File([result], blob.name, {type: blob.type})
            let formData = new FormData();
            formData.set("file", file);
            self.uploadImg(formData)
          },
          error (err) {
              console.log(err.message)
          }
      })
    },
    // 转换图片
    blobToFile(blob, fileName){
        blob.lastModifiedDate =new Date();
        blob.name = fileName;
        return blob;
    },
    // 转换图片
    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(',')
        let mime = arr[0].match(/:(.*?);/)[1];
        if(!filename) {//若无文件名则取当前时间戳
            filename = Date.parse(new Date()) + '.jpeg';
        }
        let bstr = atob(arr[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    },
    // 关闭弹窗
    closeQrcodeDialog () {
      this.isShow = false
    },
    // 判断新老工单
    async checkNewWorkOrder() {
      const res = await this.$axios.get(`${checkNewWorkOrder}`,{
        params: {
          communityId: this.$route.query.communityId
        }
      })
      if(res.status === 100) {
        this.isNewOrder = res.data.flag
        this.getTreeByRegionIdURL(res.data.regionId)
      }
    },
    // 获取新工单分类
    getTreeByRegionIdURL(regionId) {
      this.$axios.get(getTreeByRegionIdURL, {
        params: {
          regionId
        }
      }).then(res => {
        if (res.status === 100) {
          this.orderTypeList = this.handleOrderOption(res.data)
        }
      })
    },
    // 处理新工单分类选项
    handleOrderOption(typeList) {
      if(typeList.length === 0) return [];
      return typeList.map(item => {
        const children = this.handleOrderOption(item.childrenList)
        const params = {
          value: item.name,
          label: item.name,
          id: item.id
        }
        if(children.length > 0) {
          params.children = children
        }
        return params
      })
    },
    // 工单分类级联
    changeCascader(e) {
      this.form.categoryId = this.handleOrderId(this.orderTypeList, e)
      this.form.categoryName = e.slice(-1)[0]
    },
    // 根据级联数组拿到最后一级id
    handleOrderId(list, nameList) {
      nameList = JSON.parse(JSON.stringify(nameList))
      const currentName = nameList.shift()
      const findData = list.find(item => {
        if(item.label == currentName) {
          return true
        }
      })
      if(findData.children) {
        return this.handleOrderId(findData.children, nameList)
      }else {
        return findData.id
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~video.js/dist/video-js.min.css";
.patrolUpdate-container {
  .video-view-wrapper {
    height: 628px;
    padding: 0 40px;
    line-height: 628px;
    .vjs-default-skin {
      width: 80%;
      height: 100%;
      margin: 0 auto;
    }
    .failure {
      font-size: 20px;
      text-align: center;
    }
  }
  .steps-view-wrapper {
    text-align: center;
    margin-top: 40px;
    .el-steps {
      justify-content: center;
    }
  }
  .footerBtn {
    width: 500px;
    padding: 40px;
    margin: 0 auto;
    text-align: center;
  }
  .dialog-box {
    .dialog-btn {
      display: flex;
      justify-content: center;
      .v-button-container {
        margin-right: 20px;
      }
    }
  }
  .snapshotImg {
    width: 500px;
    height: 300px;
  }
  .point-table{
    margin:10px 0;
  }
  .patrol-frequency-container{
    width: 100%;
    .patrol-frequency{
      display: inline-block;
      .el-form-item{
         display: inline-block;
      }
      .timepicker{
        display: inline-block;
        // width:250px;
      }
      .delBtn{
        margin-left:8px
      }
    }
  }
  .patrol-timeline{
    margin: 15px;
  }
  .oneSelect {
    margin-right: 10px;
  }
  .order-img {
    img {
      width: 300px;
    }
  }
}
</style>
